import { useState, Fragment, useEffect } from 'react'
import { Box, Button, IconButton, Menu as MuiMenu, MenuItem as MuiMenuItem, Typography, Badge } from '@mui/material'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PerfectScrollbarComponent from 'react-perfect-scrollbar'
import CustomChip from 'src/@core/components/mui/chip'
import CustomAvatar from 'src/@core/components/mui/avatar'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError } from 'src/utils/utils'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useAuth } from 'src/hooks/useAuth'

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    //backgroundColor: '#fff',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const styles = {
  maxHeight: 349,
  '& .MuiMenuItem-root:last-of-type': {
    border: 0
  }
}

const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  ...styles
})

const Avatar = styled(CustomAvatar)({
  width: '2.375rem',
  height: '2.375rem',
  fontSize: '1.125rem'
})

const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  //flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  //textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}))

const MenuItemSubtitle = styled(Typography)({
  // flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textWrap: 'wrap',
  maxWidth: 220
  // textOverflow: 'ellipsis'
})

const NotificationDropdown = props => {
  const [loading, setLoading] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const { user } = useAuth()
  const { id } = user

  const fetchData = () => {
    setLoading(true)
    axiosInstance
      .get(ApiEndPoints.NOTIFICATION.list, {
        params: {
          isRead: 'unread'
        }
      })
      .then(response => {
        setNotificationData(response.data.data.notifications)
      })
      .catch(error => {
        toastError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = notificationId => {
    setLoading(true)
    let payload = {
      isRead: true
    }
    axiosInstance
      .patch(ApiEndPoints.NOTIFICATION.edit(notificationId), payload)
      .then(response => {
        fetchData()
      })
      .catch(error => {
        toastError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { settings } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const ScrollWrapper = ({ children }) => {
    if (hidden) {
      return <Box sx={{ ...styles, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    } else {
      return (
        <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
      )
    }
  }

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <Badge badgeContent={notificationData.length} color='secondary'>
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem disableRipple>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ fontWeight: 600 }}>Notifications</Typography>
            <CustomChip
              skin='light'
              size='small'
              label={`${notificationData.length} New`}
              color='primary'
              sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
            />
          </Box>
        </MenuItem>
        <ScrollWrapper>
          {notificationData.length === 0 ? (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant='body2' color='secondary'>
                No notifications yet
              </Typography>
            </Box>
          ) : (
            notificationData.map(notification => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  onSubmit(notification.id)
                  handleDropdownClose()
                }}
              >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Avatar alt={notification.title} src='/images/avatars/4.png' />
                  <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                    <MenuItemTitle>{notification.title}</MenuItemTitle>
                    <MenuItemSubtitle variant='body2'>{notification.body}</MenuItemSubtitle>
                  </Box>
                  <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                    {moment(notification.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              </MenuItem>
            ))
          )}
        </ScrollWrapper>
        {notificationData.length > 0 && (
          <MenuItem
            disableRipple
            sx={{ py: 3.5, borderBottom: 0, borderTop: theme => `1px solid ${theme.palette.divider}` }}
          >
            <Button
              variant='contained'
              as={Link}
              to='/notifications'
              // onClick={onSubmit}
              sx={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
            >
              Read All Notifications
            </Button>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

export default NotificationDropdown
