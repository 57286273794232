const Autocomplete = (theme, skin) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          ...(skin === 'bordered' && {
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`
          })
        },
        popupIndicator: {
          color: '#2F2B3DE5', // Change the dropdown arrow color
          '&:hover': {
            color: theme.palette.primary.main // Optional: Add hover color
          }
        }
        // clearIndicator: {
        //   color: '#2F2B3DE5', // Change the clear button color (if applicable)
        //   '&:hover': {
        //     color: theme.palette.error.main // Optional: Add hover color for clear
        //   }
        // }
      }
    }
  }
}

export default Autocomplete
